import React, { Component } from 'react';
import "../../css/DerechoInfo.css"

export default class Portabilidad extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formulario_data: props.formulario_data,
            save_form_derecho_info: props.save_form_derecho_info,
        }
        const derecho_info = {
            text_1: this.state.formulario_data.derecho_info.text_1,
        }
        if (derecho_info.text_1 === undefined)
            derecho_info.text_1 = "";
        this.state.formulario_data.derecho_info = derecho_info;
    }

    handle_text_change = (evt, text) => {
        let state = this.state;
        state.formulario_data.derecho_info[text] = evt.target.value;
        this.setState({ state });
        this.state.save_form_derecho_info(this.state.formulario_data);
    }

    render() {
        const { formulario_data } = this.state;
        const { derecho_info } = formulario_data;
        const { company_info } = formulario_data;
        return (
            <div className="DerechoInfo">
                <p className="DerechoText"><strong>MANIFIESTO Y SOLICITO:</strong><br />
                    1. Que, de conformidad con el artículo 21 del Reglamento (UE) 2016/679 del parlamento europeo y del consejo de 27 de abril de 2016 y el artículo 17 de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, por medio del presente escrito manifiesto mi deseo de ejercer el <strong>DERECHO DE PORTABILIDAD DE MIS DATOS PERSONALES</strong>, que obran en poder de
                    {" " + company_info.company_name}
                </p>
                <p className="DerechoText" style={{ "paddingLeft": "5%" }}>
                    1. Que en el plazo de un mes:<br />
                    - Se me faciliten mis datos personales en un formato estructurado, de uso común y lectura mecánica.<br />
                    - Que mis datos personales sean transmitidos directamente al responsable:<br />
                    <input type="text" value={derecho_info.text_1} onChange={(evt) => this.handle_text_change(evt, "text_1")} style={{ "width": "80%" }} /><br />
                    <i>(especifíquese nombre o razón social, siempre que sea técnicamente posible)</i>
                </p>
            </div>
        );
    }
}