import React, { Component } from 'react'
import { TailSpin } from 'react-loader-spinner'
import FormUserInfo from './FormUserInfo'
import FormDerechoInfo from './FormDerechoInfo'
import PreviewAndSend from './PreviewAndSend'
import Success from './Success'

export default class Formulario extends Component {
    state = {
        loading: true,
        page: 1,
        formulario_data: {
            company_info: {
                company_name: '',
                id_company: '',
                url_privacidad: '',
            },
            user_info: {
                nombre: '',
                dni: '',
                email: '',
                calle: '',
                numero: '',
                localidad: '',
                provincia: '',
                cp: '',
                telefono: '',
                url_documento: '',
                doc_user: '',
                has_representative: false,
                rep_nombre: '',
                rep_dni: '',
                rep_email: '',
                rep_calle: '',
                rep_numero: '',
                rep_localidad: '',
                rep_provincia: '',
                rep_cp: '',
                rep_telefono: '',
                rep_url_documento: '',
                doc_representative: '',
            },
            derecho_selected: '',
            derecho_info: {}
        },
    }

    componentDidMount() {
        var url = window.location.pathname.replace(/\//g, "")
        url = window.api_base_url + 'derechos/checkURL/' + url
        fetch(url, {
            method: 'GET', // or 'PUT'
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
                if (response === undefined) {
                    window.location.href = window.url_redirect;
                }
                else {
                    let state = this.state;
                    state.formulario_data.company_info.company_name = response.razonSocial;
                    state.formulario_data.company_info.id_company = response.idCompany;
                    state.formulario_data.company_info.url_privacidad = response.urlPrivacidad;
                    state.loading = false
                    this.setState(state);
                }
            });
    }

    prev_page = (event, formulario_data) => {
        let state = this.state;
        state.formulario_data = formulario_data;
        state.page = this.state.page - 1;
        this.setState(state);
    }

    next_page = (event, formulario_data) => {
        let state = this.state;
        state.formulario_data = formulario_data;
        state.page = this.state.page + 1;
        this.setState(state);
    }

    render() {
        const { page } = this.state;
        const { formulario_data } = this.state;
        if (this.state.loading) {
            return (
                <TailSpin
                    color="#0084FB"
                    height={200}
                    width={200}
                    timeout={0} //3 secs
                />
            )
        }
        switch (page) {
            case 1:
                return (
                    <FormUserInfo
                        next_page={this.next_page}
                        formulario_data={formulario_data}
                    />
                );
            case 2:
                return (
                    <FormDerechoInfo
                        next_page={this.next_page}
                        prev_page={this.prev_page}
                        formulario_data={formulario_data}
                    />
                );
            case 3:
                return (
                    <PreviewAndSend
                        prev_page={this.prev_page}
                        next_page={this.next_page}
                        formulario_data={formulario_data}
                    />
                );
            case 4:
                return <Success formulario_data={formulario_data} />;
            default:
                window.location.href = window.url_redirect;
        }
    }
}