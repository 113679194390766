import React, { Component } from 'react';
import { Button, Form } from 'reactstrap';

export default class FormDerechoInfo extends Component {

    render() {
        const { formulario_data } = this.props;
        const { company_info } = formulario_data
        return (
            <div>
                <h1 className="title">{company_info.company_name}</h1>
                <div className='p-5 text-white rounded'>
                    <p style={{ textAlign: "center" }}>Se ha enviado el formulario.<br />En breve contactaremos con usted.</p>
                </div>
            </div >
        );
    }
}