import React, { Component } from 'react';
import "../../css/DerechoInfo.css"

export default class Rectificacion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formulario_data: props.formulario_data,
            save_form_derecho_info: props.save_form_derecho_info,
        }
        const derecho_info = {
            text_1: this.state.formulario_data.derecho_info.text_1,
        }
        if (derecho_info.text_1 === undefined)
            derecho_info.text_1 = "";
        this.state.formulario_data.derecho_info = derecho_info;
    }

    handle_text_change = (evt, text) => {
        let state = this.state;
        state.formulario_data.derecho_info[text] = evt.target.value;
        this.setState({ state });
        this.state.save_form_derecho_info(this.state.formulario_data);
    }

    render() {
        const { formulario_data } = this.state;
        const { derecho_info } = formulario_data;
        const { company_info } = formulario_data;
        return (
            <div className="DerechoInfo">
                <p><strong>SOLICITO:</strong></p>
                <p className="DerechoText">1. Que se proceda gratuitamente a la rectificación de los siguientes datos, haciendo referencia a los documentos que se acompañan a esta solicitud y que acreditan, en caso de ser necesario, la veracidad de los nuevos datos.</p>
                <p>Datos sobre los que solicito el derecho de rectificación:</p>
                <p><textarea rows="4" value={derecho_info.text_1} onChange={(evt) => this.handle_text_change(evt, "text_1")} style={{ "width": "90%", "marginLeft": "5%" }} /></p>
                <p className="DerechoText">2. Que me comuniquen de forma escrita a la dirección arriba indicada, la rectificación de los datos una vez realizada.</p>
                <p className="DerechoText">3. Que, en el caso de que el responsable del tratamiento considere que la rectificación no procede, lo comunique igualmente, de forma motivada y dentro del plazo estipulado en la legislación vigente.</p>
            </div>
        );
    }
}