import React, { Component } from 'react';
import "../../css/DerechoInfo.css"

const textos_checks = {
    check_1: " He ejercitado derecho de rectificación de datos inexactos, en consecuencia, solicito la limitación mientras se resuelve mi petición.",
    check_2: " Considero que el tratamiento de mis datos es ilícito, y no considerando adecuada la supresión de los mismos, solicito la limitación.",
    check_3: " Aunque la organización ya no necesite mis datos, solicito la limitación del tratamiento con el fin de formular reclamaciones o ejercitar derechos.",
    check_4: " He ejercitado el derecho de oposición, y mientras se verifica si los motivos legítimos del responsable prevalecen sobre los míos solicito la limitación.",
}

export default class TratamientoDeDatos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formulario_data: props.formulario_data,
            save_form_derecho_info: props.save_form_derecho_info,
        }
        const derecho_info = {
            check_1: (this.state.formulario_data.derecho_info.check_1 === true),
            check_2: (this.state.formulario_data.derecho_info.check_2 === true),
            check_3: (this.state.formulario_data.derecho_info.check_3 === true),
            check_4: (this.state.formulario_data.derecho_info.check_4 === true),
        }
        this.state.formulario_data.derecho_info = derecho_info;
    }

    handle_check_change = (evt, check) => {
        let state = this.state;
        state.formulario_data.derecho_info[check] = !state.formulario_data.derecho_info[check];
        this.setState({ state });
        this.state.save_form_derecho_info(this.state.formulario_data);
    }

    render() {
        const { formulario_data } = this.state;
        const { derecho_info } = formulario_data;
        return (
            <div className="DerechoInfo">
                <p className="DerechoText"><strong>SOLICITO:</strong> Que se proceda a la limitación del tratamiento, en el plazo legalmente establecido, de cualesquiera datos relativos a mi persona que se encuentren en sus ficheros por los siguientes motivos (<i>marcar los que correspondan</i>):</p>
                <p className="DerechoText"><input type="checkbox" checked={derecho_info.check_1} onChange={(evt) => this.handle_check_change(evt, "check_1")} />{textos_checks.check_1}</p>
                <p className="DerechoText"><input type="checkbox" checked={derecho_info.check_2} onChange={(evt) => this.handle_check_change(evt, "check_2")} />{textos_checks.check_2}</p>
                <p className="DerechoText"><input type="checkbox" checked={derecho_info.check_3} onChange={(evt) => this.handle_check_change(evt, "check_3")} />{textos_checks.check_3}</p>
                <p className="DerechoText"><input type="checkbox" checked={derecho_info.check_4} onChange={(evt) => this.handle_check_change(evt, "check_4")} />{textos_checks.check_4}</p>
            </div>
        );
    }
}