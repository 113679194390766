import React, { Component } from 'react';
import "../../css/DerechoInfo.css"

export default class Supresion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formulario_data: props.formulario_data,
            save_form_derecho_info: props.save_form_derecho_info,
        }
        this.state.formulario_data.derecho_info = {}; //clears derecho info
        this.state.save_form_derecho_info(this.state.formulario_data);
    }

    render() {
        return (
            <div className="DerechoInfo">
                <p><strong>SOLICITO:</strong></p>
                <p className="DerechoText">1.- Que se proceda a la supresión, en el plazo legalmente establecido, de cualesquiera datos relativos a mi persona que se encuentren en sus ficheros, y se me comunique a la dirección arriba indicada.</p>
                <p className="DerechoText">2.- Que, en el caso de que el responsable del tratamiento considere que dicha supresión no procede, se me comunique igualmente, de forma motivada y dentro de los plazos indicados en la legislación vigente.</p>
                <p className="DerechoText">3.- Que en caso de que los datos personales a suprimir hubieran sido cedidos o comunicados por el responsable del tratamiento a un tercero, previamente a la presente solicitud, se comunique la supresión efectuada al cesionario, en idéntico plazo, para que éste proceda asimismo a la supresión de los datos.</p>
            </div>
        );
    }
}