import React, { Component } from 'react';
import "../../css/DerechoInfo.css"

const textos_checks = {
    check_1: " El tratamiento de mis datos personales se basa en una misión de interés público o en el ejercicio de poderes públicos conferidos al responsable del tratamiento, debiendo limitarse el tratamiento de los mismos hasta que obtenga respuesta del ejercicio de este derecho.",
    check_2: " El tratamiento de mis datos personales se basa en la satisfacción de intereses legítimos perseguidos por el responsable del tratamiento o un tercero, debiendo limitarse el tratamiento de los mismos hasta que se obtenga respuesta del ejercicio de este derecho.",
    check_3: " El tratamiento de mis datos personales se está realizando con fines de investigación científica o histórica o fines estadísticos.",
}

export default class Oposicion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formulario_data: props.formulario_data,
            save_form_derecho_info: props.save_form_derecho_info,
        }
        const derecho_info = {
            check_1: (this.state.formulario_data.derecho_info.check_1 === true),
            check_2: (this.state.formulario_data.derecho_info.check_2 === true),
            check_3: (this.state.formulario_data.derecho_info.check_3 === true),
            text_1: this.state.formulario_data.derecho_info.text_1,
        }
        if (derecho_info.text_1 === undefined)
            derecho_info.text_1 = "";
        this.state.formulario_data.derecho_info = derecho_info;
    }

    handle_check_change = (evt, check) => {
        let state = this.state;
        state.formulario_data.derecho_info[check] = !state.formulario_data.derecho_info[check];
        this.setState({ state });
        this.state.save_form_derecho_info(this.state.formulario_data);
    }

    handle_text_change = (evt, text) => {
        let state = this.state;
        state.formulario_data.derecho_info[text] = evt.target.value;
        this.setState({ state });
        this.state.save_form_derecho_info(this.state.formulario_data);
    }

    render() {
        const { formulario_data } = this.state;
        const { derecho_info } = formulario_data;
        return (
            <div className="DerechoInfo">
                <p><strong>SOLICITO:</strong></p>
                <p className="DerechoText">
                    La oposición al tratamiento de mis datos personales, teniendo en consideración que:<br />
                    <i>(Marcar los que correspondan)</i>
                </p>
                <p className="DerechoText"><input type="checkbox" checked={derecho_info.check_1} onChange={(evt) => this.handle_check_change(evt, "check_1")} />{textos_checks.check_1}</p>
                <p className="DerechoText"><input type="checkbox" checked={derecho_info.check_2} onChange={(evt) => this.handle_check_change(evt, "check_2")} />{textos_checks.check_2}</p>
                <p className="DerechoText"><input type="checkbox" checked={derecho_info.check_3} onChange={(evt) => this.handle_check_change(evt, "check_3")} />{textos_checks.check_3}</p>
                <p className="DerechoText">Sin perjuicio de que corresponde al responsable del tratamiento acreditar motivos legítimos imperiosos que prevalezcan sobre mis intereses, derechos y libertades (en los dos primeros supuestos), o una misión realizada en interés público (en el tercer supuesto), acredito como situación  personal para oponerme al tratamiento de mis datos personales:</p>
                <p><textarea rows="3" value={derecho_info.text_1} onChange={(evt) => this.handle_text_change(evt, "text_1")} style={{ "width": "90%", "marginLeft": "5%" }} /></p>
            </div>
        );
    }
}