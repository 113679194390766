import React, { Component } from 'react';
import "../../css/DerechoInfo.css"

export default class Acceso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formulario_data: props.formulario_data,
            save_form_derecho_info: props.save_form_derecho_info,
        }
        this.state.formulario_data.derecho_info = {}; //clears derecho info
        this.state.save_form_derecho_info(this.state.formulario_data);
    }

    render() {
        return (
            <div className="DerechoInfo">
                <p><strong>SOLICITO:</strong></p>
                <p className="DerechoText">1. Que la información requerida se me facilite en el plazo legalmente establecido al correo electrónico señalado.</p>
                <p className="DerechoText">2. Que la información comprenda de modo inteligible, sin utilizar claves o códigos que requieran el uso de dispositivos mecánicos específicos, los datos de carácter personal que afectan al interesado incluidos en todos sus ficheros, y los resultantes de cualquier elaboración, proceso o tratamiento, así como el origen de los datos, los cesionarios y la especificación de los concretos usos y finalidades para los que se almacenaron.</p>
            </div >
        );
    }
}