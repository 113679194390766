import React, { Component } from 'react';
import { Button, Spinner } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from '../../node_modules/pdfjs-dist/build/pdf.worker.entry'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import _ from "lodash"
import "../css/PreviewAndSend.css"

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const my_swal = withReactContent(Swal);

export default class FormDerechoInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formulario_data: props.formulario_data,
            prev_page: props.prev_page,
            next_page: props.next_page,
            accept_privacy: false,
            show_pdf_spinner: true,
            send_clicked: false,
            show_button_spinner: false,
        }
        this.get_pdf();
    }

    get_pdf() {
        const formulario_data = _.cloneDeep(this.state.formulario_data);
        delete formulario_data.user_info.doc_user;
        delete formulario_data.user_info.doc_representative;
        delete formulario_data.company_info;
        const url = window.api_base_url + 'derechos/getDerechoFormulario/' + String(this.state.formulario_data.company_info.id_company);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formulario_data)
        }).then(res => res.text())
            .then(response => {
                let state = this.state;
                state.formulario_data.url_pdf_derecho = window.api_base_url + "DownloadDocument?NAME=" + response.replace(/"/g, "");
                this.setState({ state })
            }).catch(error => {
                //console.error('Error: ', error)
                my_swal.fire(
                    <p>Fallo al cargar el documento</p>,
                    "No se pudo generar el documento PDF.",
                    'error',
                )
            })
    }

    send_emails_formulario = (event, form_data) => {
        this.setState({ send_clicked: true, show_button_spinner: true })
        const formulario_data = _.cloneDeep(form_data);
        delete formulario_data.user_info.doc_user;
        delete formulario_data.user_info.doc_representative;
        if (formulario_data.url_pdf_derecho === undefined) {
            my_swal.fire(
                <p>Ha ocurrido un error</p>,
                "No se encuentra el documento PDF a enviar",
                "error"
            )
            return 0;
        }
        //console.log("formulario_data: ", JSON.stringify(formulario_data));
        const url = window.api_base_url + 'derechos/sendEmailsFormulario';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formulario_data)
        }).then(response => {
            this.setState({ show_button_spinner: false })
            my_swal.fire(
                <p>Formulario enviado</p>,
                "Formulario enviado de forma correcta.",
                'success',
            ).then(() => {
                this.state.next_page(null, this.state.formulario_data)
            });
        }).catch(error => {
            this.setState({ show_button_spinner: false })
            //console.log('Error: ', error)
            my_swal.fire(
                <p>Fallo al enviar el formulario</p>,
                "No se pudo enviar el formulario.",
                'error',
            )
        })
    }

    handle_privacy_change = (evt) => {
        let state = this.state;
        state.accept_privacy = !state.accept_privacy;
        this.setState({ state })
    }

    on_document_load() {
        let state = this.state;
        state.show_pdf_spinner = false;
        this.setState({ state });
    }

    loading_pdf_spinner() {
        if (this.state.show_pdf_spinner) {
            return (
                <Spinner color="primary" animation="border" />
            )
        }
    }

    render() {
        const { accept_privacy, send_clicked, show_button_spinner } = this.state;
        const { formulario_data, prev_page } = this.state;
        const { company_info, url_pdf_derecho } = formulario_data;
        const { url_privacidad } = company_info;

        return (
            <div>
                <h1 className="title">{company_info.company_name}</h1>
                <div className='p-5 text-white rounded' style={{ "width": "100%" }}>
                    <p className="info-text">Compruebe que los datos del documento sean los correctos y pulse "Enviar"</p>
                    {this.loading_pdf_spinner()}
                    <Document file={url_pdf_derecho} onLoadSuccess={this.on_document_load.bind(this)}>
                        <Page scale={1.7} pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
                    </Document>
                    <p className="info-text" ><input type="checkbox" id="checkbox" checked={accept_privacy} onChange={this.handle_privacy_change} /> Acepto la <a href={url_privacidad} target="_blank" rel="noreferrer">Política de Privacidad</a>.</p>
                    <div className="d-flex justify-content-evenly">
                        <Button onClick={(event) => prev_page(event, formulario_data)} className="btn btn-danger">&larr;</Button>
                        <Button disabled={!accept_privacy || send_clicked} onClick={(event) => this.send_emails_formulario(event, formulario_data)} className="btn btn-w-m btn-primary" style={{ 'marginLeft': '10px' }}>
                            <Spinner color='white' animation='border' hidden={!show_button_spinner} />
                            <a hidden={show_button_spinner}>Enviar</a>
                        </Button>
                    </div>
                </div>
            </div >
        );
    }
}
