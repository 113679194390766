import React, { Component } from 'react';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "../css/DerechoInfo.css"
import TratamientoDeDatos from './CamposDinamicos/TratamientoDeDatos';
import Portabilidad from './CamposDinamicos/Portabilidad';
import Acceso from './CamposDinamicos/Acceso';
import Supresion from './CamposDinamicos/Supresion';
import Oposicion from './CamposDinamicos/Oposicion';
import Rectificacion from './CamposDinamicos/Rectificacion';

const derechos = ["TRATAMIENTO_DE_DATOS", "PORTABILIDAD", "ACCESO", "SUPRESION", "OPOSICION", "RECTIFICACION"]

const nombres_derechos = {
    TRATAMIENTO_DE_DATOS: "Derecho a la limitación en el tratamiento de datos",
    PORTABILIDAD: "Derecho de portabilidad",
    ACCESO: "Derecho de acceso",
    SUPRESION: "Derecho de supresion",
    OPOSICION: "Derecho de oposicion",
    RECTIFICACION: "Derecho de rectificación",
}
export default class FormDerechoInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formulario_data: props.formulario_data,
            prev_page: props.prev_page,
            next_page: props.next_page,
            dropdown_is_open: false,
        }
    }

    toggle_dropdown = () => {
        let state = this.state;
        state.dropdown_is_open = !state.dropdown_is_open;
        this.setState({ state })
    }

    select_derecho = (evt, derecho) => {
        let state = this.state;
        state.formulario_data.derecho_selected = derecho;
        this.setState({ state })
    }

    save_form_derecho_info = (formulario_data) => {
        let state = this.state;
        state.formulario_data = formulario_data;
        this.setState(state);
    }

    derecho_input_component() {
        switch (this.state.formulario_data.derecho_selected) {
            case derechos[0]:
                return <TratamientoDeDatos formulario_data={this.state.formulario_data} save_form_derecho_info={this.save_form_derecho_info} />
            case derechos[1]:
                return <Portabilidad formulario_data={this.state.formulario_data} save_form_derecho_info={this.save_form_derecho_info} />
            case derechos[2]:
                return <Acceso formulario_data={this.state.formulario_data} save_form_derecho_info={this.save_form_derecho_info} />
            case derechos[3]:
                return <Supresion formulario_data={this.state.formulario_data} save_form_derecho_info={this.save_form_derecho_info} />
            case derechos[4]:
                return <Oposicion formulario_data={this.state.formulario_data} save_form_derecho_info={this.save_form_derecho_info} />
            case derechos[5]:
                return <Rectificacion formulario_data={this.state.formulario_data} save_form_derecho_info={this.save_form_derecho_info} />
            default:
                return <div><br /><br /><br /><br /><br /><br /></div>
        }
    }

    render() {
        const { formulario_data, next_page, prev_page, dropdown_is_open } = this.state;
        const { company_info } = formulario_data;
        const { derecho_selected } = formulario_data;
        return (
            <div>
                <h1 className="title">{company_info.company_name}</h1>
                <div className='p-5 rounded'>
                    <Dropdown isOpen={dropdown_is_open} toggle={this.toggle_dropdown} size="lg">
                        <DropdownToggle style={{ maxWidth: "fit-content" }} className="btn btn-primary dropdown-toggle DerechoText" tag="span" data-toggle='dropdown' aria-expanded={dropdown_is_open}>
                            {derecho_selected === "" ? "Seleccione el derecho a ejercer" : nombres_derechos[formulario_data.derecho_selected]}
                        </DropdownToggle>
                        <DropdownMenu className='lg'>
                            <DropdownItem onClick={(evt) => this.select_derecho((evt), derechos[0])}>{nombres_derechos[derechos[0]]}</DropdownItem>
                            <DropdownItem onClick={(evt) => this.select_derecho((evt), derechos[1])}>{nombres_derechos[derechos[1]]}</DropdownItem>
                            <DropdownItem onClick={(evt) => this.select_derecho((evt), derechos[2])}>{nombres_derechos[derechos[2]]}</DropdownItem>
                            <DropdownItem onClick={(evt) => this.select_derecho((evt), derechos[3])}>{nombres_derechos[derechos[3]]}</DropdownItem>
                            <DropdownItem onClick={(evt) => this.select_derecho((evt), derechos[4])}>{nombres_derechos[derechos[4]]}</DropdownItem>
                            <DropdownItem onClick={(evt) => this.select_derecho((evt), derechos[5])}>{nombres_derechos[derechos[5]]}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <p></p>
                    {this.derecho_input_component()}
                    <div className="d-flex justify-content-evenly">
                        <Button onClick={(event) => prev_page(event, formulario_data)} className="btn btn-danger">&larr;</Button>
                        <Button onClick={(event) => next_page(event, formulario_data)} className="btn btn-w-m btn-primary" disabled={derecho_selected === '' || derecho_selected === undefined}>Siguiente</Button>
                    </div>
                </div>
            </div >
        );
    }
}